<template>
 <div>
  <div
   class="-mt-5 pb-4 bg-white sm:pb-4"
   v-if="
    totalAnswered >= 0 &&
    totalUnanswered >= 0 &&
    this.$route.name !== 'ExpertStatisticsAnsweredUnansweredWeekDay' &&
    this.$route.name !== 'ExpertStatisticsAnsweredUnansweredWeekYear'
   "
  >
   <div class="flex justify-end">
    <div class="max-w-xl px-4 sm:px-6 lg:px-8">
     <div class="max-w-xl mx-auto">
      <dl class="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
       <div class="flex flex-col border-b border-gray-100 p-1 text-center sm:border-0 sm:border-r">
        <dt class="order-1 mt-0 text-sm leading-6 font-medium text-gray-500">Total</dt>
        <dd class="order-2 text-sm font-extrabold text-gray-900">
         {{ totalAnswered + totalUnanswered }}
        </dd>
       </div>
       <div
        class="flex flex-col border-t border-b border-gray-100 p-1 text-center sm:border-0 sm:border-l sm:border-r"
       >
        <dt class="order-1 mt-0 text-sm leading-6 font-medium text-gray-500">Answered</dt>
        <dd class="order-2 text-sm font-extrabold text-gray-900">
         {{ totalAnswered }}
        </dd>
       </div>
       <div class="flex flex-col border-t border-gray-100 p-1 text-center sm:border-0 sm:border-l">
        <dt class="order-1 mt-0 text-sm leading-6 font-medium text-gray-500">Unanswered</dt>
        <dd class="order-2 text-sm font-extrabold text-gray-900">
         {{ totalUnanswered }}
        </dd>
       </div>
      </dl>
     </div>
    </div>
   </div>
  </div>
  <apexchart
   :width="this.width"
   :height="this.height"
   :options="chartOptions"
   :series="this.seriesForColumn ? this.seriesForColumn : series"
  ></apexchart>
 </div>
</template>

<script>
export default {
 name: "BasicColumnChart",
 props: [
  "seriesForColumn",
  "categoriesForColumn",
  "width",
  "height",
  "colors",
  "title",
  "totalAnswered",
  "totalUnanswered",
 ],
 data() {
  return {
   series: [],
   chartOptions: {
    title: {
     text: this.title,
     align: "left",
     margin: 10,
     offsetX: 0,
     offsetY: 0,
     floating: false,
     style: {
      fontSize: "14px",
      fontWeight: "bold",
      fontFamily: undefined,
      color: "#263238",
     },
    },
    noData: {
     text: "No data",
     align: "center",
     verticalAlign: "middle",
     offsetX: 0,
     offsetY: 0,
     style: {
      color: undefined,
      fontSize: "14px",
      fontFamily: undefined,
     },
    },

    colors: ["#97BAA6", "#E34B5F", "#1E7889"],
    chart: {
     type: "bar",
    },
    plotOptions: {
     bar: {
      horizontal: false,
      columnWidth: "55%",
      endingShape: "rounded",
     },
    },
    dataLabels: {
     enabled: false,
    },
    xaxis: {
     categories: this.categoriesForColumn ? this.categoriesForColumn : [],
     labels: {
      rotate: -75,
      rotateAlways: true,
      style: {
       colors: [],
       fontSize: "12px",
       fontFamily: "Helvetica, Arial, sans-serif",
       fontWeight: 500,
       cssClass: "apexcharts-xaxis-label",
      },
      /**
       * Allows users to apply a custom formatter function to x-axis labels.
       *
       * @param { String } value - The default value generated
       * @param { Number } timestamp - In a datetime series, this is the raw timestamp
       * @param { object } contains dateFormatter for datetime x-axis
       */
      formatter: function (value, timestamp, opts) {
       let finalValue = "";
       if (value.length > 0) {
        let valueFirstTwo = value.slice(0, 2);
        let valueLastTwo = value.slice(-2);
        if (value.length == 4) {
         finalValue = valueFirstTwo + ":" + valueLastTwo;
        } else if (value.length == 2) {
         finalValue = valueFirstTwo + ":" + "00";
        }
       }

       return finalValue;
      },
     },
    },
    yaxis: {
     title: {
      text: "calls count",
     },
    },
   },
  };
 },
 methods: {},
 mounted() {
  this.$emit("loading", false);
 },
};
</script>

<style></style>
