<template>
  <div id="page-container" class="">
    <div class="content-wrap pb-5">
      <SuccessBanner
        :key="key"
        :msg="successMsg"
        :active="activeBanner"
        @closeBanner="activeBanner = false"
        :isSuccess="isSuccess"
      />
      <PbxHeader
        @openForm="
          (this.openForm = !this.openForm), (this.openUpdateForm = false)
        "
        :buttonTitle="$t('pbx.newGroup')"
        v-model:search="search"
        pbxMenuType="reportList"
        @select-hostname="this.selectHostname = $event"
      />
    </div>
    <EditReport
      v-if="this.openEditForm"
      @closeEditForm="this.openEditForm = false"
      :name="this.reportName"
      :id="this.reportId"
      :emails="this.reportEmail"
      :cron="this.reportCron"
      :urlType="this.urlType"
      @activeBanner="
        (activeBanner = true),
          getReportList(),
          (key = !key),
          (this.openEditForm = false)
      "
      @successMsg="successMsg = $event"
      @success="isSuccess = $event"
    />
    <div class="flex flex-col">
      <div class="sm:-mx-6 lg:-mx-0">
        <div class="py-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
          <div
            v-if="
              (elementsReportQueues.length > 0 && this.urlType === 'queue') ||
                (elementsReportExtensions.length > 0 &&
                  this.urlType === 'extension')
            "
          >
            <TablePagination
              :tableData="
                this.urlType === 'extension'
                  ? this.filteredExtension()
                  : this.filteredQueue()
              "
              :urlType="this.urlType"
              @sort="sort($event)"
              @openEditForm="
                (...args) => {
                  this.openEditFormId(...args);
                }
              "
              @activeBanner="
                (activeBanner = true), getReportList(), (key = !key)
              "
              @successMsg="successMsg = $event"
              @success="isSuccess = $event"
            />
          </div>
          <div
            v-else-if="
              (elementsReportQueues.length > 0 && !isLoading) ||
                (elementsReportExtensions.length > 0 && !isLoading)
            "
            class="flex justify-center"
          >
            <div class="text-center">
              <font-awesome-icon
                icon="chart-line"
                class="mx-auto h-12 w-12 text-gray-400"
              />

              <h3 class="mt-2 text-lg font-medium text-gray-900">
                {{ $t("noReportTitle") }}
              </h3>
              <p class="mt-1 text-base text-gray-500">
                {{ $t("noReportDescription") }}.
              </p>
              <div class="mt-6">
                <button
                  type="button"
                  class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white encom_primary encom_primary_hover encom_ring_color"
                  @click="this.$router.push(`/expert-statistics/queue`)"
                >
                  <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  Go dashboard
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Loading
    v-model:active="isLoading"
    :can-cancel="true"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { PlusIcon } from "@heroicons/vue/solid";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import PbxDashboard from "../../components/PBX/PbxDashboard.vue";
import PbxHeader from "../../components/PBX/PbxHeader.vue";
import TablePagination from "../../components/TablePagination.vue";
import EditReport from "../../components/PBX/EditReport.vue";
import SuccessBanner from "../../components/SuccessBanner.vue";
import EmptyStateReportList from "../../components/PBX/EmptyStateReportList.vue";
export default {
  props: ["urlType"],
  components: {
    EditReport,
    EmptyStateReportList,
    Loading,
    PbxDashboard,
    PbxHeader,
    PlusIcon,
    TablePagination,
    SuccessBanner,
  },
  data() {
    return {
      activeBanner: false,
      data: undefined,
      currentSort: "id",
      currentSortDir: "asc",
      dataSeriesCurrentMonth: [],
      dataSeriesStacked: [],
      dataSeriesConcatenated: [],
      elementExtensions: [],
      elementQueues: [],
      elementsReportExtensions: [],
      elementsReportQueues: [],
      extensions: [],
      fullPage: true,
      isAllSelected: "",
      isSuccess: true,
      isLoading: false,
      key: false,
      openEditForm: false,
      periodSelector: undefined,
      pbxMapData: {},
      reportLists: {},
      reportId: null,
      reportName: "",
      reportParameters: "",
      reportEmail: "",
      reportCron: "",
      search: "",
      selectHostname: "",
      successMsg: "",
      textMonth: "",
      timeSelected: [],
    };
  },
  methods: {
    async getReportList() {
      let pbx = "";
      if (!this.selectHostname) {
        pbx = this.hostName;
      } else {
        pbx = this.selectHostname;
      }
      try {
        this.isLoading = true;
    this.$emit("setLoading", true);

        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/presentations`,
          {
            params: { pbx: pbx },
            headers: {
              Authorizations: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        this.reportLists = res.data;
        this.elementsReportExtensions = res.data.extension;
        this.elementsReportQueues = res.data.queue;
      } catch (error) {
        console.error(error);
        this.errorHandling(error);
      } finally {
        this.isLoading = false;
    this.$emit("setLoading", false);
      }
    },
    async getPbxMap() {
      if (this.hostName && this.hostName !== "null") {
        try {
          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/pbxMap/${this.hostName}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          this.pbxMapData = res.data;
        } catch (error) {
          this.errorHandling(error);
        }
      }
    },
    setTimeSelected(event) {
      this.timeSelected = event;
    },

    openEditFormId(...args) {
      this.openEditForm = true;
      this.reportId = args[0];
      this.reportName = args[1];
      this.reportEmail = args[3];
      this.reportCron = args[4];
    },
    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
    sortedQueues() {
      return this.elementsReportQueues.sort((a, b) => {
        let toBeSorted = this.currentSort;
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (toBeSorted === "nickname" || toBeSorted === "email_addresses") {
          if (a[toBeSorted].toLowerCase() < b[toBeSorted].toLowerCase())
            return -1 * modifier;
          if (a[toBeSorted].toLowerCase() > b[toBeSorted].toLowerCase())
            return 1 * modifier;
        } else {
          if (a[toBeSorted] < b[toBeSorted]) return -1 * modifier;
          if (a[toBeSorted] > b[toBeSorted]) return 1 * modifier;
        }
        return 0;
      });
    },
    filteredQueue() {
      return this.sortedQueues().filter((element) => {
        return element.nickname
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
    sortedExtensions() {
      return this.elementsReportExtensions.sort((a, b) => {
        let toBeSorted = this.currentSort;
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (toBeSorted === "nickname" || toBeSorted === "email_addresses") {
          if (a[toBeSorted].toLowerCase() < b[toBeSorted].toLowerCase())
            return -1 * modifier;
          if (a[toBeSorted].toLowerCase() > b[toBeSorted].toLowerCase())
            return 1 * modifier;
        } else {
          if (a[toBeSorted] < b[toBeSorted]) return -1 * modifier;
          if (a[toBeSorted] > b[toBeSorted]) return 1 * modifier;
        }
        return 0;
      });
    },
    filteredExtension() {
      return this.sortedExtensions().filter((element) => {
        return element.nickname
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
  },
  mounted() {
    this.getReportList();
    this.getPbxMap();
  },
  computed: {
    ...mapGetters([
      "account",
      "pbxElements",
      "pbxTimeSelected",
      "hostName",
      "hostTimeZone",
      "pbxElementType",
      "pbxPeriodSelected",
    ]),
    currentMonthAndYearForPbx() {
      let options = { month: "short" };
      let month = String("0" + (new Date().getMonth() + 1)).slice(-2);
      let formattedMonth = new Intl.DateTimeFormat("fr-FR", options)
        .format(month)
        .slice(0, -2);
      this.textMonth = formattedMonth;
      let year = new Date().getFullYear();
      return String(year) + String(month);
    },
  },
};
</script>

<style>
#page-container {
  position: relative;
  min-height: 97%;
}
#content-wrap {
  padding-bottom: 2rem; /* Footer height */
}
#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem; /* Footer height */
}
</style>
