<template>
 <div>
  <h1>{{ this.id }}</h1>

  <apexchart width="100%" :options="chartOptions" :series="series"></apexchart>
 </div>
</template>

<script>
export default {
 name: "DonutChart",
 props: ["showChart", "id", "dataSeries", "chartLabel", "title", "subtitle", "colors"],
 data() {
  return {
   series: this.dataSeries,
   chartOptions: {
    colors:
     this.colors.length > 0
      ? this.colors
      : [
         "#97BAA6",
         "#14233C",
         "#E34B5F",
         "#194E63",
         "#1E7889",
         "#D9B3A3",
         "#EFB0A1",
         "#5B9998",
         "#A5A59D",
         "#CAAB9F",
        ],
    plotOptions: {
     pie: {
      donut: {
       size: "45%",
       labels: {
        show: true,
        value: {
         show: true,
         fontSize: "14px",
         fontWeight: 400,
         offsetY: 0,
         color: undefined,
         formatter: function (val) {
          return val + " appels";
         },
        },
        total: {
         show: true,
         showAlways: true,
         fontSize: "16px",
         fontWeight: 600,
         color: "#373d3f",
         formatter: function (w) {
          let t = w.globals.seriesTotals.reduce((a, b) => parseInt(a) + parseInt(b));
          return t >= 10000 ? (t / 1000).toFixed(1) + "K" : t;
         },
        },
       },
      },
     },
    },

    responsive: [
     {
      breakpoint: 680,
      options: {
       chart: {
        width: "350",
        offsetX: -10,
       },
       title: {
        text: this.title,
        align: "center",
        style: {
         fontSize: "8px",
        },
       },
       subtitle: {
        text: this.subtitle,
        align: "center",
        offsetY: 25,
        style: { fontSize: "8px" },
       },
       legend: {
        position: "bottom",
        offsetX: -10,
        offsetY: 0,
       },
      },
     },
     {
      breakpoint: 1460,
      options: {
       chart: {
        width: "300",
        offsetX: -10,
       },
       title: {
        text: this.title,
        align: "center",
        style: {
         fontSize: "8px",
        },
       },
       subtitle: {
        text: this.subtitle,
        align: "center",
        offsetY: 25,
        style: { fontSize: "8px" },
       },
       legend: {
        position: "bottom",
        offsetX: -10,
        offsetY: 0,
       },
      },
     },
    ],
    dataLabels: {
     enabled: true,
     enabledOnSeries: [1],
    },
    chart: {
     type: "donut",
     events: {},
    },
    title: {
     text: this.title,
     align: "center",
     margin: 10,
     style: {
      fontSize: "12px",
     },
    },
    subtitle: {
     text: this.subtitle,
     align: "center",
     offsetY: 25,
     style: { fontSize: "15px", fontWeight: "normal" },
    },
    legend: {
     show: true,
     position: "bottom",
    },
    labels: this.chartLabel,
    noData: {
     text: "Loading...",
     align: "center",
     verticalAlign: "middle",
     offsetX: 0,
     offsetY: 0,
     style: {
      color: undefined,
      fontSize: "14px",
      fontFamily: undefined,
     },
    },
   },
  };
 },
};
</script>

<style></style>
